import { gql } from '@apollo/client';

const GET_PROJECTS = gql`
    query getProjects {
        projects {
            id
            name
            status
            dueDate
        }
    }
`;

const GET_PROJECT = gql`
    query getProject($id: ID!) {
        project(id: $id) {
            id
            name
            description
            status
            dueDate
            client {
                id
                name
                email
                phone
            }
        }
    }
`;

export { GET_PROJECTS, GET_PROJECT };